import {reactive, ref} from "vue";
import type {FormInstance, FormRules} from "element-plus";

const ruleFormRef = ref<FormInstance>()
const successForm = ref(false);
const dialogError = ref(false);
interface RuleForm {
    name: string
}

const ruleForm = reactive<RuleForm>({
    name: ''
});

const rules = reactive<FormRules<RuleForm>>({
    name: [
        {required: true, message: 'Proszę podać nazwę', trigger: 'blur'},
        {min: 3, message: 'Minimum 3 znaki', trigger: 'blur'},
    ]
})

export default {
    ruleFormRef,
    ruleForm,
    rules,
    successForm,
    dialogError
}