import {defineStore} from 'pinia';
import {usePeerStore} from "@/stores/peer";
import {useSocket} from "@/stores/socket";
import dialog from "@/composable/form/dialog";

const {dialogError} = dialog

export const useMediaDevicesStore = defineStore('mediaDevice', {
    state: () => ({
        videos: [] as any,
        myVideo: ''
    }),
    getters: {
        getVideos(state) {
            return state.videos;
        },
        getMyVideo(state) {
            return state.myVideo;
        }
    },
    actions: {
        addVideoStream(stream, first:boolean|false, name:string) {
            if(first) {
                this.myVideo = stream;
            }
            const obj = {};
            obj['stream'] = stream;
            obj['name'] = name
            this.videos.push(obj);
            this.videos = [...new Map(this.videos.map(item =>
                [item['stream']['id'], item])).values()];
        },
        async addMediaDevices(constraints, name) {
            try {
                await navigator.mediaDevices
                    .getUserMedia(constraints)
                    .then(stream => {
                        this.addVideoStream(stream, true, name);
                        usePeerStore().call(stream);
                        useSocket().userConnected(stream);
                    })
            } catch (error) {
                dialogError.value = true;
                throw new Error(error);
            }
        }
    }
})
