import { defineStore } from 'pinia';
import { Peer } from "peerjs";
import { uuid } from 'vue-uuid';
import { useSocket } from '@/stores/socket';
import { useMediaDevicesStore } from '@/stores/mediaDevices';

export const usePeerStore = defineStore('peer', {
    state: () => ({
        peerObject: new Peer(uuid.v1(), {
            path: '/peerjs',
            host: window.location.hostname,
            port: process.env.VUE_APP_BACKEND_PORT,
            debug: 3
        })
    }),
    getters: {
      getPeer() {
          return this.peerObject;
      }
    },
    actions: {
        call(stream) {
            this.peerObject.on("call", (call) => {
                call.answer(stream);
                call.on("stream", (userVideoStream) => {
                    useMediaDevicesStore().addVideoStream(userVideoStream, false);
                });
            });
        },
        open(roomId) {
            this.peerObject.on("open", (id) => {
                useSocket().joinRoom(roomId, id);
            })
        },
        connectToNewUser(userId, stream) {
            const call = this.peerObject.call(userId, stream);
            call.on("stream", (userVideoStream) => {
                useMediaDevicesStore().addVideoStream(userVideoStream, false);
            });
        }
    }
})
