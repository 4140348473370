import { defineStore } from 'pinia';
import io from 'socket.io-client';
import { usePeerStore } from '@/stores/peer';

export const useSocket = defineStore('socket', {
    state: () => ({
        socket: io(window.location.hostname+":4000"),
        messages: [],
        user: ''
    }),
    getters: {
        getMessages(state) {
            return state.messages;
        },
        getUser(state) {
            return state.user;
        }
    },
    actions: {
        userConnected(stream) {
            this.socket.on("user-connected", (userId) => {
                usePeerStore().connectToNewUser(userId, stream)
            });
        },
        joinRoom(roomId, id) {
            this.socket.emit("join-room", roomId, id, this.user);
        },
        sendMessage(message) {
            this.socket.emit('message', message);
        },
        createMessage() {
            this.socket.on("createMessage", (message, userName) => {
                const obj = {};
                obj['message'] = message;
                obj['user'] = userName
                this.messages.push(obj);
            });
        },
        setUserName(user) {
            this.user = user;
        }
    }
})
